<template>
    <div>
        <!--面包屑-->
        <div class="page-breadcrumb">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/template/list' }">列表</el-breadcrumb-item>
                <el-breadcrumb-item>TEST</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <!--内容-->
        <div class="page-content-x">
            <!--标题-->
            <el-row>
                <el-col :span="12"><h3>{{ page_name }}</h3></el-col>
            </el-row>
            <div style="height: 20px;"></div>
            <!--详情-->
            <el-row>
                <el-col :span="16">
                    <el-form ref="form" :model="form" size="medium" :label-width="this.env.label_width">
                        <el-form-item label="地图">
                            <Amap ref="amap" :clickMap="dianditu" :geolocation="xxaa" style="height: 600px;"/>
                        </el-form-item>

                        <el-form-item label="按钮">
                            <el-button type="primary" size="medium" @click="dingdian">按钮</el-button>
                            <el-button type="primary" size="medium" @click="dingdian2">按钮</el-button>
                        </el-form-item>

                        <el-form-item>
                            <el-button type="primary" size="medium" @click="onSubmit">保存</el-button>
                            <el-button>取消</el-button>
                        </el-form-item>
                    </el-form>
                </el-col>
            </el-row>
        </div>
    </div>
</template>
<script>
import Amap from '@/components/Amap'

export default {
    components: {
        Amap,
    },
    data() {
        return {
            testaaa: '',
            page_name: 'TEST',
            form: {
                name: '',
            }
        }
    },
    // 创建
    created() {

    },
    // 安装
    mounted() {
        // this.init()

    },
    methods: {
        xxaa(e) {
            console.log('定位', e)

        },
        // 点地图
        dianditu(e) {
            console.log('点地图1', e)
            this.$refs.amap.marker([e.lnglat.lng, e.lnglat.lat]);
        },
        init() {
            // map = new AMap.Map('custom_amap', {
            //     resizeEnable: true,
            //     center: [116.397428, 39.90923],
            //     zoom: 13
            // });


        },

        dingdian() {
            console.log('点标记')

            let xxx = [
                [121.532215, 31.113243],
                [121.467671, 31.072083],
                [121.767771, 31.072583],
            ]
            console.log('多组坐标', xxx)

            let xxxa = [121.532215, 31.113243]
            console.log('一组坐标', xxxa)

            // this.$refs.amap.marker(xxx,true);
            this.$refs.amap.marker(xxx);
            // this.$refs.amap.createdMap();
        },
        dingdian2() {

            this.$refs.amap.position2address([121.767771, 31.072583],(dd)=>{
                console.log(dd)
            });
            // this.$refs.amap.address2position('张江地铁站',(dd)=>{
            //     console.log(dd)
            // });
        },

        onSubmit() {
            console.log(this.form)
            this.$message({
                message: '点击了保存', type: 'success',
                duration: this.env.message_duration,
                onClose: () => {
                    console.log(111)
                }
            });
        },

    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#custom_amap {
    width: 500px;
    height: 500px;
    background: #3a8ee6;
}

</style>
